import React from 'react';
import { GET_HOME_PAGE } from 'api/pages/queries';
import {
  HomePageFragmentsFragment,
  PageIdType,
  PageQuery,
  PageQueryVariables,
} from 'types';
import { createApolloClient, setDataFromTreeToCache } from 'lib/apolloClient';
import { SeoHead, PageWrapper } from 'layouts';
import { Home, HomeProps } from 'components';
import { GetStaticProps, GetStaticPropsContext, NextPage } from 'next';
import { PreviewDataProps } from '../lib/previewApi';
import { ParsedUrlQuery } from 'querystring';
import { getWPLocale, logger } from '../utils';
import { EventIds } from '../lib';
import { PreviewModeBanner } from '../components/PreviewBanner';
import NextHead from 'next/head';

type HomePageProps = {
  data: HomePageFragmentsFragment | null;
  isPreviewEnabled?: boolean;
};

export const HomePage: NextPage<HomePageProps> = ({
  data,
  isPreviewEnabled,
}) => {
  return (
    <PageWrapper headerColor="white" translations={data?.translations}>
      <SeoHead seoHead={data?.seo?.fullHead} />
      <NextHead>
        <meta name="yandex-verification" content="e97293809b18c90f" />
      </NextHead>
      {isPreviewEnabled && <PreviewModeBanner />}
      {data && <Home data={data} />}
    </PageWrapper>
  );
};

const getHomePageData = async (
  props: GetStaticPropsContext<ParsedUrlQuery, PreviewDataProps>
) => {
  const isPreview = !!props.preview;
  const previewToken =
    isPreview && props.previewData?.token ? props.previewData?.token : null;
  // Create new apollo client instead of calling `initializeApolloClient` which returns a shared one.
  // We do that to avoid caching all posts data in apollo cache, which is then sent to client
  const apolloClient = createApolloClient();

  const { data: pageData, error } = await apolloClient.query<
    PageQuery,
    PageQueryVariables
  >({
    query: GET_HOME_PAGE,
    variables: {
      asPreview: isPreview,
      id: isPreview ? `${props.previewData?.id}` : '/',
      idType: isPreview ? PageIdType.DatabaseId : PageIdType.Uri,
    },
    errorPolicy: 'all',
    ...(previewToken ? { context: { previewToken } } : {}),
  });

  const currentPageLocale = pageData?.page?.locale?.locale;
  const wpLocale = getWPLocale(props.locale);

  // find translation is such way because for SEO purpose we need to keep locale as "fr" (from next.config.js) instead of "fr_FR"
  const data =
    wpLocale === currentPageLocale
      ? pageData?.page
      : pageData?.page?.translated?.find(
          (item) => item?.locale?.locale === wpLocale
        ) || pageData?.page;

  return {
    data: data ?? null,
    error,
  };
};

export const getStaticProps: GetStaticProps<
  HomePageProps,
  ParsedUrlQuery,
  PreviewDataProps
> = async (props) => {
  const { data, error } = await getHomePageData(props);

  if (error) {
    logger.error(
      'Getting error on home page fetch',
      EventIds.HomePageError,
      error
    );
  }

  if (data === null && !error) {
    logger.error('Home page data is empty', EventIds.HomePageError, undefined, {
      locale: props.locale,
    });
  }

  const dataFromTreeToCacheFn = setDataFromTreeToCache(HomePage, {
    componentProps: {
      data,
    },
  });

  const propsResult = await dataFromTreeToCacheFn(props);
  propsResult.props = {
    ...propsResult.props,
    isPreviewEnabled: !!props.preview,
    data,
  };

  return propsResult;
};

export default HomePage;

import React from 'react';
import { useRouter } from 'next/router';
import { Button } from '../../ui';

export const PreviewModeBanner: React.FC = () => {
  const { push } = useRouter();

  return (
    <div
      id="preview-banner"
      style={{
        position: 'fixed',
        bottom: 0,
        width: '100vw',
        backgroundColor: 'rgba(255,64,64,0.31)',
        height: 'auto',
        // Max available value
        zIndex: 2147483647,
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '5px',
        }}
      >
        <div style={{ alignSelf: 'center' }}>Your are in preview mode</div>
        <Button
          onClick={() => {
            push('/api/exit-preview');
          }}
        >
          Exit preview
        </Button>
      </div>
    </div>
  );
};
